.bolt__brand {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    /* background: rgba(44, 70, 190, 1); */
    /* ff 3.6+ */
    background: -moz-linear-gradient( 90deg, rgba(44, 70, 190, 1) 32%, rgba(44, 70, 190, 1) 56%, rgba(103, 58, 183, 1) 100%);
    /* safari 5.1+,chrome 10+ */
    background: -webkit-linear-gradient( 90deg, rgba(44, 70, 190, 1) 32%, rgba(44, 70, 190, 1) 56%, rgba(103, 58, 183, 1) 100%);
    /* opera 11.10+ */
    background: -o-linear-gradient( 90deg, rgba(44, 70, 190, 1) 32%, rgba(44, 70, 190, 1) 56%, rgba(103, 58, 183, 1) 100%);
    /* ie 6-9 */
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#673AB7', endColorstr='#2C46BE', GradientType=0);
    /* ie 10+ */
    background: -ms-linear-gradient( 90deg, rgba(44, 70, 190, 1) 32%, rgba(44, 70, 190, 1) 56%, rgba(103, 58, 183, 1) 100%);
    /* global 94%+ browsers support */
    background: linear-gradient( 90deg, rgba(44, 70, 190, 1) 32%, rgba(44, 70, 190, 1) 56%, rgba(103, 58, 183, 1) 100%);
}

.bolt__brand div {
    flex: 1;
    max-width: 150px;
    min-width: 120px;
    margin: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
}