.team-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.main_container {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  flex-direction: column;
  padding: 2rem;
  margin: 4rem;
}
.heading {
  font-size: 40px;
}
.bolt__features-container__feature-title {
  /* margin: left 1rem; */
  flex: 1;
  max-width: 500px;
  /* margin-right: 2rem; */
  /* margin-top: 2rem; */
}
.bolt__features-container__feature-title h1 {
  font-family: var(--font-family);
  font-weight: 800;
  font-size: 18px;
  line-height: 40px;
  letter-spacing: -0.04em;
  color: #fff;
}

.bolt__features-container__feature-title div {
  /* margin: 1rem; */
  width: 38px;
  height: 3px;
  background: var(--gradient-bar);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin-bottom: 0.25rem;
}

@media screen and (max-width: 550px) {
  .bolt__features-container__feature-title h1 {
    font-size: 14px;
    line-height: 22px;
  }
  .heading {
    font-size: 30px;
  }
}
