.bolt__possibility {
    display: flex;
    flex-direction: row;
}

.bolt__possibility-image {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-right: 2rem;
}

.bolt__possibility-image img {
    width: 100%;
    /* height: 50%; */
    height: 80%;
    object-fit: contain;
}

.bolt__possibility-content {
    flex: 1;
    display: flex;
    /* justify-content: flex-end; */
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    /* background: red; */
}

.bolt__possibility-content h4 {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    color: #71e5ff;
}

.bolt__possibility-content h4:last-child {
    color: #ff8a71;
}

.bolt__possibility-content h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 34px;
    line-height: 45px;
    margin: 1rem 0;
}

.bolt__possibility-content p {
    color: var(--color-text);
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    margin-bottom: 2rem;
}

@media screen and (max-width: 950px) {
    .bolt__possibility {
        flex-direction: column;
    }
    .bolt__possibility-image {
        margin: 1rem 0;
    }
    /* .bolt__possibility-image img {
        width: unset;
        height: unset;
    } */
    .bolt__possibility-content {
        margin-top: 2rem;
    }
}

@media screen and (max-width: 700px) {
    .bolt__possibility-image img {
        /* width: 100%; */
        height: 20%;
        /* object-fit: contain; */
    }
}