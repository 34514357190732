.bolt__header {
  display: flex;
  flex-direction: row;
  background: #e6e9ef;
}

.bolt__header-content {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  margin-right: 5rem;
}

.bolt__header-content h1 {
  font-family: var(--font-family);
  font-weight: 800;
  font-size: 62px;
  line-height: 80px;
  letter-spacing: -0.04em;
}

.bolt__header-content p {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: var(--color-text);
  margin-top: 1.5rem;
}

.bolt__header-content__input {
  width: 100%;
  margin: 2rem 0 1rem;
  display: flex;
  flex-direction: row;
}

.bolt__header-content__input input {
  flex: 2;
  width: 100%;
  min-height: 50px;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  background: var(--color-footer);
  border: 2px solid var(--color-footer);
  padding: 0 1rem;
  outline: none;
  color: #e6e9ef;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.bolt__header-content__input button {
  flex: 0.6;
  width: 100%;
  min-height: 50px;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  background: #00a7ab;
  border: 2px solid #00a7ab;
  padding: 0 1rem;
  color: #e6e9ef;
  cursor: pointer;
  outline: none;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.bolt__header-content__people {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  margin-top: 2rem;
}

.bolt__header-content__people img {
  width: 181.79px;
  height: 38px;
}

.bolt__header-content__people p {
  margin: 0 0 0 1rem;
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 12px;
  line-height: 38px;
  color: black;
  text-align: center;
}

.bolt__header-image {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

.bolt__header-image img {
  width: 100%;
  /* height: 100%; */
  height: 80%;
  object-fit: contain;
}

@media screen and (max-width: 1050px) {
  .bolt__header {
    flex-direction: column;
  }
  .bolt__header-content {
    margin: 0 0 3rem;
  }
}

@media screen and (max-width: 650px) {
  .bolt__header-content h1 {
    font-size: 48px;
    line-height: 60px;
  }
  .bolt__header-content p {
    font-size: 16px;
    line-height: 24px;
  }
  .bolt__header-content__people {
    flex-direction: column;
  }
  .bolt__header-content__people p {
    margin: 0;
  }
  .bolt__header-content__input input,
  .bolt__header-content__input button {
    font-size: 16px;
    line-height: 24px;
  }
}

@media screen and (max-width: 490px) {
  .bolt__header-content h1 {
    font-size: 36px;
    line-height: 48px;
  }
  .bolt__header-content p {
    font-size: 14px;
    line-height: 24px;
  }
  .bolt__header-content__input input,
  .bolt__header-content__input button {
    font-size: 12px;
    line-height: 16px;
  }
}
