/* .bolt__logo {
    width: 15%;
    height: auto;
    margin: 1rem 2rem;
} */

.bolt__navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 5rem;
}

.bolt__navbar-links {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.bolt__navbar-links_logo {
  margin-right: 2rem;
}

.bolt__navbar-links_logo img {
  width: 200px;
  height: 100px;
  /* border-radius: 15px; */
  border-radius: 10px 0px 10px 0px;
}

.bolt__navbar-links_container {
  display: flex;
  flex-direction: row;
}

.bolt__navbar-sign {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.bolt__navbar-links_container p,
.bolt__navbar-sign p,
.bolt__navbar-menu_container p {
  color: #e6e9ef;
  font-family: var(--font-family);
  font-weight: 900;
  font-size: 18px;
  line-height: 25px;
  text-transform: capitalize;
  margin: 0 1rem;
  cursor: pointer;
}

.bolt__navbar-sign button,
.bolt__navbar-menu_container button {
  padding: 0.5rem 1rem;
  color: #e6e9ef;
  /* background:#64FBD2 */
  background: #00a7ab;
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 5px;
}

.bolt__navbar-menu {
  margin-left: 1rem;
  display: none;
  position: relative;
}

.bolt__navbar-menu svg {
  cursor: pointer;
}

.bolt__navbar-menu_container {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
  text-align: end;
  background: var(--color-footer);
  padding: 2rem;
  position: absolute;
  right: 0;
  top: 40px;
  margin-top: 1rem;
  min-width: 210px;
  border-radius: 5px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}

.bolt__navbar-menu_container p {
  margin: 1rem 0;
}

.bolt__navbar-menu_container-links-sign {
  display: none;
}

.bolt__navbar-menu_container p:hover,
.bolt__navbar-links_container p:hover,
.bolt__navbar-menu_container-links-sign p:hover,
.bolt__navbar-menu_container-links-sign button:hover {
  background: white;
  /* color: black; */
  border: 1px solid white;
  border-radius: 15px;
  padding: 0.5rem;
  /* background: #64fbd2; */
}

@media screen and (max-width: 1200px) {
  .bolt__navbar {
    padding: 3rem 5rem;
  }
  .bolt__navbar-links_logo img {
    width: 180px;
    height: 90px;
    /* border-radius: 15px; */
    /* border-radius: 10px 0px 10px 0px; */
  }
}

@media screen and (max-width: 1050px) {
  .bolt__navbar-links_container {
    display: none;
  }
  .bolt__navbar-menu {
    display: flex;
  }
  .bolt__navbar-links_logo img {
    width: 160px;
    height: 80px;
    /* border-radius: 15px; */
    /* border-radius: 10px 0px 10px 0px; */
  }
}

@media screen and (max-width: 700px) {
  .bolt__navbar {
    padding: 2rem 4rem;
  }
}

@media screen and (max-width: 550px) {
  .bolt__navbar {
    padding: 2rem;
  }
  .bolt__navbar-sign {
    display: none;
  }
  .bolt__navbar-menu_container {
    top: 20px;
  }
  .bolt__navbar-menu_container-links-sign {
    display: block;
  }
}
