/* .contact-form-container {
  text-align: center;
  padding: 40px;
  background-color: #f5f5f5;
}

.contact-form {
  max-width: 400px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

input,
textarea {
  margin: 10px 0;
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
}

button {
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

button:hover {
  background-color: #0056b3;
} */

.contact-form-container {
  text-align: center;
  padding: 40px;
  /* background-color: #f8f9fa; */
  background: var(--gradient-bar);
}
.gradient__bg {
  /* ff 3.6+ */
  background: -moz-linear-gradient(
    90deg,
    rgba(44, 70, 190, 1) 32%,
    rgba(44, 70, 190, 1) 56%,
    rgba(103, 58, 183, 1) 100%
  );
  /* safari 5.1+,chrome 10+ */
  background: -webkit-linear-gradient(
    90deg,
    rgba(44, 70, 190, 1) 32%,
    rgba(44, 70, 190, 1) 56%,
    rgba(103, 58, 183, 1) 100%
  );
  /* opera 11.10+ */
  background: -o-linear-gradient(
    90deg,
    rgba(44, 70, 190, 1) 32%,
    rgba(44, 70, 190, 1) 56%,
    rgba(103, 58, 183, 1) 100%
  );
  /* ie 6-9 */
  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#673AB7', endColorstr='#2C46BE', GradientType=0);
  /* ie 10+ */
  background: -ms-linear-gradient(
    90deg,
    rgba(44, 70, 190, 1) 32%,
    rgba(44, 70, 190, 1) 56%,
    rgba(103, 58, 183, 1) 100%
  );
  /* global 94%+ browsers support */
  background: linear-gradient(
    90deg,
    rgba(44, 70, 190, 1) 32%,
    rgba(44, 70, 190, 1) 56%,
    rgba(103, 58, 183, 1) 100%
  );
}
/* .contact-form {
  max-width: 400px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  position: relative;
} */
.innerContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
}
.innerContainer_image {
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.innerContainer_form {
  width: 50%;
}
.contact-form-image {
  /* max-width: 500px; */
  /* width: 40%; */
  /* margin-bottom: 20px; */
  /* position: absolute; */
  /* left: -200px; */
  /* top: 50%; */
  /* transform: translateY(-50%); */
}
.contact-form {
  /* max-width: 400px; */
  /* width: 50%; */
  margin: 0 auto;
  padding: 8%;
  display: flex;
  flex-direction: column;
  background-color: rgba(44, 70, 190, 1);
  /* background-color: #ffffff; */
  /* padding: 30px; */
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

.contact-form img {
  max-width: 100px;
  margin-bottom: 20px;
}

input,
textarea {
  margin: 10px 0;
  padding: 12px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
}

button {
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 12px 24px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

button:hover {
  background-color: #0056b3;
}

@media screen and (max-width: 550px) {
  /* .contact-form {
    width: 100%;
  } */
  .innerContainer {
    width: 100%;
    display: block;
    /* margin: 1rem 0; */
  }
  .innerContainer_image {
    width: 100%;
  }
  .innerContainer_form {
    width: 100%;
  }
}
