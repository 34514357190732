/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap");
:root {
  --font-family: "Manrope", sans-serif;
  /* --gradient-text: linear-gradient(89.97deg, #ae67fa 1.84%, #f49867 102.67%);
    --gradient-bar: linear-gradient(103.22deg, #ae67fa -13.86%, #f49867 99.55%); */
  --gradient-text: linear-gradient(
    90deg,
    rgba(0, 142, 238, 1) 39%,
    rgba(100, 251, 210, 1) 100%
  );
  --gradient-bar: linear-gradient(103.22deg, #ae67fa -13.86%, #f49867 99.55%);
  /* --color-bg: #040c18; */
  /* --color-bg: #e6e9ef; */
  --color-bg: rgba(44, 70, 190, 1);
  /* --color-footer: #94a7d2; */
  /* --color-footer: #7ab5b7; */
  /* --color-footer: #031b34; */
  --color-footer: black;
  --color-blog: #042c54;
  --color-text: #81afdd;
  --color-subtext: #ff8a71;
}
