/* .team-member {
  text-align: center;
  margin: 20px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease-in-out;
  flex: 1;
  max-width: 300px;
  background: #e6e9ef;
}

.team-member:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.team-member-photo {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 15px;
}

.team-member-name {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #333;
}

.team-member-description {
  font-size: 1rem;
  color: #666;
} */

.team-member {
  text-align: center;
  width: 20rem;
  height: 18rem;
  margin: 20px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease-in-out;
  /* background: #e6e9ef; */
  background: var(--gradient-bar);
  /* display: flex; */
  align-items: center;
  justify-content: center;
}

.team-member:hover {
  box-shadow: 0 16px 24px rgba(0, 0, 0, 0.2);
  /* background: transparent; */
  border: none;
  /* padding: 45px; */
  width: 22rem;
  height: 20rem;
}
.team-member:hover > .team-member-name {
  color: white;
}
.team-member:hover > .team-member-description {
  color: wheat;
}

.team-member-photo {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 15px;
}
.team-member:hover > .team-member-photo {
  width: 180px;
  height: 180px;
  /* border-radius: 50%;
  object-fit: cover;
  margin-bottom: 15px; */
}

.team-member-name {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #333;
}

.team-member-description {
  font-size: 1rem;
  color: #666;
}

/* Responsive styles */
@media (max-width: 768px) {
  .team-member {
    width: 80%; /* Adjust the width for smaller screens */
    margin: 20px auto;
  }

  .team-member-photo {
    width: 120px;
    height: 120px;
  }

  .team-member-name {
    font-size: 1.2rem;
  }

  .team-member-description {
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .team-member {
    width: 90%; /* Further adjust the width for smaller screens */
    height: 15rem;
  }

  .team-member-photo {
    width: 100px;
    height: 100px;
  }

  .team-member-name {
    font-size: 1rem;
  }

  .team-member-description {
    font-size: 0.8rem;
  }
}
